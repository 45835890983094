import {
  ADD_GROUP_TO_PASSENGER,
  ADD_PASSENGER,
  EDIT_PASSENGER,
  CLEAN_PASSENGERS,
} from "../actionTypes";

const addPassenger = (passenger) => {
  return {
    type: ADD_PASSENGER,
    payload: passenger,
  };
};

const cleanPassengers = () => {
  return {
    type: CLEAN_PASSENGERS,
  };
};

const addGroupToPassenger = (passengerId, group) => {
  return {
    type: ADD_GROUP_TO_PASSENGER,
    payload: {
      passengerId: passengerId,
      group: group,
    },
  };
};

const editPassenger = (passengerId, newData) => {
  return {
    type: EDIT_PASSENGER,
    payload: {
      id: passengerId,
      ...newData,
    },
  };
};

export { addPassenger, addGroupToPassenger, editPassenger, cleanPassengers };
