import { CircularProgress } from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Gilroy from "../fonts/Gilroy-Medium.ttf";
import GilroyBold from "../fonts/Gilroy-Extrabold.ttf";
import { getPaymentById } from "../services/paymentService";
import { processErrorResponse } from "./processErrorResponse";
import { decrypted } from "../components/shared/encrypt";
import { Button } from "../ui";
// Create styles

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const signUrl =
  "https://res.cloudinary.com/dyvyiepbv/image/upload/v1696077099/Screenshot_2023-09-30_at_05.28.11_rxfidz.png";
const PAYMENT_FLOOR = 1000;

const styles = StyleSheet.create({
  page: {
    color: "black",
    alignItems: "center",
    padding: 50,
  },
  section: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: 10,
    padding: 10,
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  basicDateContainer: {
    borderRadius: 1,
    height: 30,
    width: 30,
    borderWidth: 1,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
  },
  dateItem: {
    display: "flex",
    alignItems: "center",
  },
  textLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
  },
  paymentContainer: {
    width: 120,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    marginLeft: 10,
  },
  brandName: {
    fontFamily: "Gilroy",
    fontSize: 10,
    alignSelf: "center",
    marginTop: 3,
  },
  imgLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  signatureContainer: {
    justifyContent: "flex-end",
    flex: 1,
    paddingLeft: 120,
  },
  footerLabel: { fontFamily: "Gilroy", fontSize: 8, marginLeft: 3 },
  reciptContainer: {
    borderRadius: 10,
    backgroundColor: "black",
    width: 120,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  reciptNumberContainer: {
    width: 120,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
  },
  passengerNameContainer: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginVertical: 20,
  },
  commonText: { fontSize: 10, fontFamily: "Gilroy" },
  boldText: { fontFamily: "Gilroy", fontWeight: 600 },
  paymentNumberContainer: {
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
  },
  conceptContainer: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    alignItems: "center",
  },
  footerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  flexRow: { display: "flex", flexDirection: "row" },
  loaderContainer: { width: "100%", marginTop: 100, textAlign: "center" },
  font: { fontFamily: "Gilroy" },
  downloadPdfContainer: {
    display: "flex",
    height: "100vh",
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  pdfLoaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
});

const instagramLogo = {
  uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688225307/instagram_jyymdu.png",
};

const locationLogo = {
  uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688225129/location-2_xtxggd.png",
};

const worldLogo = {
  uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688225307/global_g4gx5e.png",
};

const phoneLogo = {
  uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688225234/phone-call-2_fwq3zh.png",
};

// Función para eliminar el texto entre paréntesis de un concepto
function removeParenthesesText(concept) {
  return concept.replace(/\s*\(.*?\)\s*/g, "").trim();
}

// Create Document Component
const BasicDocument = () => {
  Font.register({
    family: "Gilroy",
    format: "truetype",
    src: Gilroy,
  });

  Font.register({
    family: "GilroyBold",
    format: "truetype",
    src: GilroyBold,
  });
  let { id } = useParams();
  id = decrypted(id);
  const [payment, setPayment] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPaymentById(id)
      .then((res) => {
        res = { ...res, concept: removeParenthesesText(res.concept) };
        setPayment(res);
        setIsLoading(false);
      })
      .catch((e) => {
        processErrorResponse(e);
        setIsLoading(false);
        setError(true);
      });
  }, []);

  const getPdfContent = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.header}>
              <View>
                <Image
                  style={{ width: 150 }}
                  source={{
                    uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688224644/college-logo-recibo_nzxycz.png",
                  }}
                ></Image>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.reciptContainer}>
                  <Text style={[styles.font, { color: "white" }]}>RECIBO</Text>
                </View>
                <View style={styles.paymentNumberContainer}>
                  <View style={styles.reciptNumberContainer}>
                    <Text style={styles.font}>
                      Nº {PAYMENT_FLOOR + payment.id}
                    </Text>
                  </View>
                  <Text style={{ fontSize: 10 }}>RUT 215124100016</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.header}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={styles.dateItem}>
                  <Text style={styles.commonText}>dia</Text>
                  <View
                    style={[
                      styles.basicDateContainer,
                      {
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      },
                    ]}
                  >
                    <Text style={styles.commonText}>
                      {payment.date.split("/")[0]}
                    </Text>
                  </View>
                </View>
                <View style={styles.dateItem}>
                  <Text style={styles.commonText}>mes</Text>
                  <View style={[styles.basicDateContainer]}>
                    <Text style={styles.commonText}>
                      {payment.date.split("/")[1]}
                    </Text>
                  </View>
                </View>
                <View style={styles.dateItem}>
                  <Text style={styles.commonText}>año</Text>
                  <View
                    style={[
                      styles.basicDateContainer,
                      {
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                      },
                    ]}
                  >
                    <Text style={styles.commonText}>
                      {payment.date.split("/")[2]}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.paymentContainer}>
                  <Text style={styles.font}>USD {payment.ammount}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.textSection}>
            <View style={styles.textLine}>
              <Text style={styles.font}>Recibimos de </Text>
              <View style={styles.passengerNameContainer}>
                <Text style={styles.boldText}>
                  {payment.passengerName + " " + payment.passengerLastName}
                </Text>
              </View>
            </View>
            <View style={styles.textLine}>
              <Text style={styles.font}>La cantidad de </Text>
              <View style={styles.conceptContainer}>
                <Text style={styles.boldText}>
                  {payment.ammount} {payment.currency}
                </Text>
              </View>
            </View>
            <View style={styles.textLine}>
              <Text style={styles.font}>Por concepto de </Text>
              <View style={styles.conceptContainer}>
                <Text style={styles.boldText}>{payment.concept}</Text>
              </View>
            </View>
          </View>
          <View style={styles.container}>
            <View>
              <Text style={{ fontFamily: "GilroyBold" }}>
                EL VIAJE DE TUS SUEÑOS
              </Text>
            </View>
            <View style={styles.signatureContainer}>
              <Image
                style={{ width: 150 }}
                source={{
                  uri: signUrl,
                }}
              ></Image>
              <View
                style={{
                  borderBottomColor: "black",
                  borderBottomWidth: 1,
                }}
              ></View>
              <Text style={styles.brandName}>College Viajes S.A.S</Text>
            </View>
          </View>
          <View style={styles.footerContainer}>
            <View style={styles.imgLogo}>
              <Image source={phoneLogo} style={{ width: 14 }}></Image>
              <Text style={styles.footerLabel}>+598 27088888</Text>
            </View>
            <View style={styles.imgLogo}>
              <Image source={worldLogo} style={{ width: 14 }}></Image>
              <Text style={styles.footerLabel}>
                Av. Brasil 3150, Montevideo
              </Text>
            </View>
            <View style={styles.imgLogo}>
              <Image source={locationLogo} style={{ width: 14 }}></Image>
              <Text style={styles.footerLabel}>www.college.com.uy</Text>
            </View>
            <View style={styles.imgLogo}>
              <Image source={instagramLogo} style={{ width: 14 }}></Image>
              <Text style={styles.footerLabel}>college.uy</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const fileName = `recibocollege${
    payment.passengerName +
    payment.passengerLastName +
    PAYMENT_FLOOR +
    payment.id
  }.pdf`;
  return (
    <>
      {loading ? (
        <div style={styles.loaderContainer}>
          <CircularProgress />{" "}
        </div>
      ) : error ? (
        <h3>Error generando recibo. Intente nuevamente. </h3>
      ) : isMobile ? (
        <div style={styles.downloadPdfContainer}>
          <PDFDownloadLink document={getPdfContent()} fileName={fileName}>
            {({ blob, url, loading, error }) =>
              loading ? (
                <div style={styles.pdfLoaderContainer}>
                  <CircularProgress />
                  <span>Generando recibo</span>
                </div>
              ) : (
                <Button customStyles={{ alignSelf: "center" }}>
                  DESCARGAR RECIBO
                </Button>
              )
            }
          </PDFDownloadLink>
        </div>
      ) : (
        <PDFViewer style={styles.viewer}>
          {/* Start of the document*/}
          <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <View style={styles.header}>
                  <View>
                    <Image
                      style={{ width: 150 }}
                      source={{
                        uri: "https://res.cloudinary.com/dyvyiepbv/image/upload/v1688224644/college-logo-recibo_nzxycz.png",
                      }}
                    ></Image>
                  </View>
                  <View style={styles.flexRow}>
                    <View style={styles.reciptContainer}>
                      <Text style={[styles.font, { color: "white" }]}>
                        RECIBO
                      </Text>
                    </View>
                    <View style={styles.paymentNumberContainer}>
                      <View style={styles.reciptNumberContainer}>
                        <Text style={styles.font}>
                          Nº {PAYMENT_FLOOR + payment.id}
                        </Text>
                      </View>
                      <Text style={{ fontSize: 10 }}>RUT 215124100016</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.header}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={styles.dateItem}>
                      <Text style={styles.commonText}>dia</Text>
                      <View
                        style={[
                          styles.basicDateContainer,
                          {
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                          },
                        ]}
                      >
                        <Text style={styles.commonText}>
                          {payment.date.split("/")[0]}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.dateItem}>
                      <Text style={styles.commonText}>mes</Text>
                      <View style={[styles.basicDateContainer]}>
                        <Text style={styles.commonText}>
                          {payment.date.split("/")[1]}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.dateItem}>
                      <Text style={styles.commonText}>año</Text>
                      <View
                        style={[
                          styles.basicDateContainer,
                          {
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                          },
                        ]}
                      >
                        <Text style={styles.commonText}>
                          {payment.date.split("/")[2]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.flexRow}>
                    <View style={styles.paymentContainer}>
                      <Text style={styles.font}>USD {payment.ammount}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.textSection}>
                <View style={styles.textLine}>
                  <Text style={styles.font}>Recibimos de </Text>
                  <View style={styles.passengerNameContainer}>
                    <Text style={styles.boldText}>
                      {payment.passengerName + " " + payment.passengerLastName}
                    </Text>
                  </View>
                </View>
                <View style={styles.textLine}>
                  <Text style={styles.font}>La cantidad de </Text>
                  <View style={styles.conceptContainer}>
                    <Text style={styles.boldText}>
                      {payment.ammount} {payment.currency}
                    </Text>
                  </View>
                </View>
                <View style={styles.textLine}>
                  <Text style={styles.font}>Por concepto de </Text>
                  <View style={styles.conceptContainer}>
                    <Text style={styles.boldText}>{payment.concept}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.container}>
                <View>
                  <Text style={{ fontFamily: "GilroyBold" }}>
                    EL VIAJE DE TUS SUEÑOS
                  </Text>
                </View>
                <View style={styles.signatureContainer}>
                  <Image
                    style={{ width: 150 }}
                    source={{
                      uri: signUrl,
                    }}
                  ></Image>
                  <View
                    style={{
                      borderBottomColor: "black",
                      borderBottomWidth: 1,
                    }}
                  ></View>
                  <Text style={styles.brandName}>College Viajes S.A.S</Text>
                </View>
              </View>
              <View style={styles.footerContainer}>
                <View style={styles.imgLogo}>
                  <Image source={phoneLogo} style={{ width: 14 }}></Image>
                  <Text style={styles.footerLabel}>+598 27088888</Text>
                </View>
                <View style={styles.imgLogo}>
                  <Image source={worldLogo} style={{ width: 14 }}></Image>
                  <Text style={styles.footerLabel}>
                    Av. Brasil 3150, Montevideo
                  </Text>
                </View>
                <View style={styles.imgLogo}>
                  <Image source={locationLogo} style={{ width: 14 }}></Image>
                  <Text style={styles.footerLabel}>www.college.com.uy</Text>
                </View>
                <View style={styles.imgLogo}>
                  <Image source={instagramLogo} style={{ width: 14 }}></Image>
                  <Text style={styles.footerLabel}>college.uy</Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default BasicDocument;
