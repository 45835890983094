import React, { useState } from "react";
import backgroundMobile from "../../../assets/AlbumMobileThird.jpg";
import backgroundDesktop from "../../../assets/AlbumDesktopThird.jpg";
import useMobileDetector from "../../../hooks/useMobileDetector";

const Third = ({ name, trip }) => {
  const isMobile = useMobileDetector();

  const backgroundImageMobile = {
    backgroundImage: `url(${backgroundMobile})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
  };
  const backgroundImagDesktop = {
    backgroundImage: `url(${backgroundDesktop})`,
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
  };

  return (
    <div style={isMobile ? backgroundImageMobile : backgroundImagDesktop}></div>
  );
};

export default Third;
