import { Select as MUISelect } from "@mui/material";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import useMobile from "../hooks/useOrientation";

const Select = ({ options, onChange, placeholder, short }) => {
  const isMobile = useMobile();
  return (
    <FormControl style={{ width: isMobile ? "100%" : short ? 200 : 400 }}>
      <InputLabel>{placeholder}</InputLabel>
      <MUISelect options={options} onChange={onChange} label={placeholder}>
        {options &&
          options.map((o) => (
            <MenuItem key={o.key} value={o.key} defaultValue={o.default}>
              {o.label}
            </MenuItem>
          ))}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
