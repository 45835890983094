import { combineReducers } from "@reduxjs/toolkit";
import { createStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import passengersReducer from "./reducers/passengersReducer";
import notificationReducer from "./reducers/notificationReducer";
const store = createStore(
  combineReducers({
    passengers: passengersReducer,
    user: userReducer,
    notification: notificationReducer,
  })
);

export default store;
