import React, { useRef } from "react";
import Modal from "react-modal";
import { CircularProgress, Input } from "@mui/material";
import { Button } from "../../ui";
import useMobile from "../../hooks/useOrientation";
const EditUserDataModal = ({
  onCloseModal,
  user,
  onEdituser,
  editMessage,
  loading,
}) => {
  const phone = useRef(user.phone);
  const isMobile = useMobile();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: isMobile ? "auto" : 500,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      style={customStyles}
      contentLabel="Example Modal"
      isOpen={true}
      ariaHideApp={false}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button onClick={() => onCloseModal()}>CERRAR</Button>

        <h2>Cambiar telefono</h2>
        <Input
          style={{ marginTop: 10, marginBottom: 10 }}
          onChange={(e) => (phone.current = e.target.value)}
          placeholder={user.phone}
        ></Input>
        <Button
          style={{ marginTop: 15 }}
          variant="contained"
          onClick={() =>
            onEdituser({
              id: user.id,
              phone: phone.current,
            })
          }
        >
          EDITAR
        </Button>
        {editMessage && (
          <h3 style={{ textAlign: "center", paddingTop: 10 }}>{editMessage}</h3>
        )}
        <div style={{ alignSelf: "center" }}>
          {loading && <CircularProgress></CircularProgress>}
        </div>
      </div>
    </Modal>
  );
};

export default EditUserDataModal;
