import React, { useContext, useState } from "react";
import Responsible from "./steps/Second";
import Passenger from "./steps/First";
import SelectTrip from "./steps/Third";
import RegistrationFinished from "./steps/Third";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui";
import {
  ValidateCI,
  ValidateDate,
  ValidateEmail,
  ValidatePhone,
} from "../shared/regex";
import secureLocalStorage from "react-secure-storage";
import useMobile from "../../hooks/useOrientation";
import ProgressBar from "@ramonak/react-progress-bar";
import { warning } from "../../ui/colors";
import First from "./steps/First";
import Second from "./steps/Second";
import Third from "./steps/Third";
import { addAlbumReservation } from "../../services/albumService";
import { CircularProgress } from "@mui/material";
import Bugsnag from "@bugsnag/browser";
import { SessionContext } from "../../helpers/session";

const schools = {
  "ST BRENDANS": 1,
  "SANTO DOMINGO": 1,
  INGLES: 1,
  "MONTEVIDEO COLLEGE": 1,
  "RICHARD ANDERSON": 1,
  SAYAGO: 1,
  LATINOAMERICANO: 2,
  ALEMAN: 2,
  VARELA: 2,
  "ELBIO FERNANDEZ": 2,
  WOODLANDS: 3,
  "JESUS MARIA": 3,
  "ANGLO- CARRASCO": 3,
  "SCUOLA ITALIANA": 3,
  "SANTA RITA": 4,
  CRANDON: 4,
  "ST GEORGE": 4,
  "LA MENNAIS": 4,
  SALTO: 5,
  "EMPALME OLMOS": 6,
  "CANELONES - NEXO": 6,
  CANELONES: 6,
  TABARE: 6,
  LOGOSOFICO: 6,
  "SUAREZ - LICEO 7": 6,
  "LICEO N 55": 6,
  "LICEO 31": 6,
  "LICEO 5": 6,
  "NOTRE DAME": 6,
  MISERICORDISTAS: 6,
  "COLONIA - LAMANA": 6,
  "TREINTA Y TRES": 6,
  MELO: 7,
  FLORIDA: 7,
  MINAS: 7,
  PAYSANDU: 7,
  RIVERA: 7,
  "FRAY BENTOS": 7,
  TACUAREMBO: 7,
  "FLORIDA - HUERTO": 7,
  "PASO DE LOS TOROS": 7,
  LIBERTAD: 7,
  FRANCES: 8,
  "EDU SCHOOL": 8,
  "SANTA ELENA": 8,
  IC: 8,
  IUA: 8,
  MALDONADO: 8,
  "SAN PABLO": 9,
  "CLARA JACKSON": 9,
  "HANS CHRISTIAN ANDERSEN": 9,
  SAFA: 9,
  MATURANA: 9,
};

const AlbumScreen = () => {
  const { user } = useContext(SessionContext);
  const id = user?.id;
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [userRegisteredId, setUserRegisteredId] = useState(
    secureLocalStorage.getItem("registeredUser")
  );

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [albumData, setAlbumData] = useState({
    ParentName: "",
    ParentLastName: "",
    ParentPhone: "",
    PassengerName: "",
    PassengerLastName: "",
    PassengerDocumentId: "",
    HighSchool: "",
    NightNumber: "",
    isAlbum1: undefined,
  });

  const moveForward = () => {
    setErrorMessage("");
    if (step === 2) {
      const {
        ParentName,
        ParentLastName,
        ParentPhone,
        PassengerName,
        PassengerLastName,
        PassengerDocumentId,
        HighSchool,
        NightNumber,
        isAlbum1,
      } = albumData;
      if (
        stringNotEmpty(ParentName) &&
        stringNotEmpty(ParentLastName) &&
        stringNotEmpty(ParentPhone) &&
        stringNotEmpty(PassengerName) &&
        stringNotEmpty(PassengerLastName) &&
        stringNotEmpty(PassengerDocumentId) &&
        stringNotEmpty(HighSchool) &&
        stringNotEmpty(NightNumber) &&
        isAlbum1 !== undefined
      ) {
        setErrorMessage("");
        setLoading(true);
        addAlbumReservation(albumData)
          .then((res) => {
            Bugsnag.notify("Reserva de album.", function (event) {
              event.severity = "info";
              event.addMetadata("ExtraData", albumData);
            });
            setStep(step + 1);
          })
          .catch((e) => {
            console.log(e);
            setErrorMessage("Error enviando formulario.");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setErrorMessage("Se deben completar todos los campos.");
      }
    } else {
      setStep(step + 1);
    }
  };

  const stringNotEmpty = (word) => {
    return word;
  };

  const getComponentStep = () => {
    switch (step) {
      case 1:
        return <First />;
      case 2:
        return (
          <Second
            data={albumData}
            onChangeData={(data) => setAlbumData(data)}
            schools={schools}
          />
        );
      case 3:
        return <Third />;
      default:
    }
  };

  const isLastStep = step === 3;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        {getComponentStep()}
      </div>
      {step === 2 && (
        <h4 style={{ color: warning, alignSelf: "center" }}>{errorMessage}</h4>
      )}
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!isLastStep ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 10,
                }}
              >
                {step > 1 && (
                  <Button
                    customStyles={{ margin: 5 }}
                    variant="contained"
                    onClick={() => setStep(step - 1)}
                  >
                    <h3 style={{ margin: 0 }}>Anterior</h3>
                  </Button>
                )}
                <Button
                  customStyles={{
                    margin: 5,
                    alignSelf: isMobile ? "center" : null,
                  }}
                  variant="contained"
                  onClick={moveForward}
                >
                  <h3 style={{ margin: 0 }}>
                    {step === 1 ? "Quiero mi album!" : "Terminar"}
                  </h3>
                </Button>
              </div>
              {loading ? <CircularProgress /> : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AlbumScreen;
