import React, { useEffect } from "react";
import Bugsnag from "@bugsnag/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHouse, faPhone, faMobile } from "@fortawesome/free-solid-svg-icons";
import calendar from "../../ui/icons/calendar.png";
import map from "../../ui/icons/map.png";
import smartphoneCall from "../../ui/icons/smartphone-call.png";
import viber from "../../ui/icons/viber.png";
import { primary } from "../../ui/colors";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";

library.add(faHouse);
library.add(faPhone);
library.add(faMobile);

export default function Contact() {
  const styles = {
    circle: {
      display: "flex",
      padding: 10,
      width: 40,
      height: 40,
      alignItems: "center",
      textAlign: "center",
      borderRadius: "50%",
      justifyConent: "center",
      backgroundColor: primary,
      justifyContent: "center",
    },
    itemRow: {
      display: "flex",
      alignItems: "center",
      margin: 10,
    },
    icon: {
      width: 32,
    },
    text: {
      marginLeft: 10,
    },
  };

  const redirectToWhatsApp = () => {
    var phoneNumber = "59892686393"; // Replace with your phone number

    // Construct the WhatsApp URL
    var url = "https://api.whatsapp.com/send?phone=" + phoneNumber;

    // Open WhatsApp in a new tab/window
    window.open(url, "_blank");
  };
  return (
    <div style={{ padding: 10 }}>
      <h1>Contacto</h1>
      <div style={styles.itemRow}>
        <div style={styles.circle}>
          <img alt="" src={map} style={styles.icon} />
        </div>
        <h3 style={styles.text}>Av Brasil 3150, Montevideo, Uruguay</h3>
      </div>
      <div style={styles.itemRow}>
        <div style={styles.circle}>
          <img alt="" src={viber} style={{ width: 32 }} />
        </div>
        <h3 style={styles.text}>27088888</h3>
      </div>
      <div style={styles.itemRow}>
        <div style={styles.circle}>
          <img alt="" src={calendar} style={{ width: 32 }} />
        </div>
        <h3 style={styles.text}>Lunes a Viernes - 10:00 a 19:00hs</h3>
      </div>
      <div style={styles.itemRow}>
        <div style={styles.circle}>
          <img alt="" src={smartphoneCall} style={{ width: 32 }} />
        </div>
        <h3 style={styles.text}>
          <span style={{ color: "#25D366" }} onClick={redirectToWhatsApp}>
            <u>Escribinos por WhatsApp!</u>
          </span>
        </h3>
      </div>
    </div>
  );
}

const Map = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  return (
    <div className="App">
      {!isLoaded ? (
        <h1>Cargando mapa...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={{ lat: 18.52043, lng: 73.856743 }} />
        </GoogleMap>
      )}
    </div>
  );
};
