import { fetchGetApi, fetchGetApiNoAuth } from "./fetch";

export const getGroupByProgramAndTrip = async (programId, tripId) => {
  const res = await fetchGetApi(
    `/programs/${programId}/trips/${tripId}/groups`
  );
  return res;
};

export const getGroupByTripId = async (tripId, groupId) => {
  const res = await fetchGetApi(`/groups/${groupId}`);
  return res;
};

export const getAllNamesByProgramId = async (programId) => {
  const res = await fetchGetApiNoAuth(`/programs/${programId}/groups`);
  return res;
};
