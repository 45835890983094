import React, { useState } from "react";
import Modal from "react-modal";
import { CircularProgress, Input } from "@mui/material";
import { Button } from "../../ui";
import { editUserPassword } from "../../services/userService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { ValidatePassword } from "../shared/regex";
import FileUploader from "../shared/FileUploader";
import useMobile from "../../hooks/useOrientation";

const AddSignModal = ({
  onCloseModal,
  user,
  paymentData,
  onSendPaymentData,
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [ammount, setAmmount] = useState();
  const isMobile = useMobile();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? 300 : 700,
    },
  };

  const handleFileUpload = (file, type) => {
    if (type > -1 && file) {
      setFile({ file: file, type: type });
    }
  };

  const handleSubmitPayment = () => {
    const paymentData = {
      ammount: ammount,
      file: file,
      currency: "usd",
      concept: "Seña",
    };
    onSendPaymentData(paymentData);
  };

  return (
    <Modal
      style={customStyles}
      contentLabel="Example Modal"
      isOpen={true}
      ariaHideApp={false}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Button onClick={() => onCloseModal()}>Cerrar</Button>

        <h2 style={{ alignSelf: "center" }}>Ingresar pago</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            style={{ marginTop: 10, marginBottom: 10 }}
            type="number"
            onChange={(e) => setAmmount(e.target.value)}
            placeholder={"Ingresar monto depositado en USD"}
          ></Input>
          <FileUploader onFileUploaded={handleFileUpload} />
          {file ? (
            <div>
              {file.type === 0
                ? "Imagen cargada correctamente."
                : "PDF cargado correctamente."}
              <Button onClick={() => window.open(file.file)}>
                ver archivo
              </Button>
            </div>
          ) : null}
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={handleSubmitPayment}
            disabled={!ammount || !file?.file}
          >
            AGREGAR PAGO
          </Button>
          {loading && (
            <div style={{ alignSelf: "center" }}>
              <CircularProgress></CircularProgress>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddSignModal;
