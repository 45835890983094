import { primary } from "./colors";
import useMobile from "../hooks/useOrientation";

const LabelWithValue = ({ label, value }) => {
  const isMobile = useMobile();

  const getStyles = !isMobile
    ? {
        container: {
          display: "flex",
          flexDirection: "row",
          width: 400,
        },
        labelContainer: {
          display: "flex",
          flex: 1,
          alignItems: "center",
          maxWidth: 200,
        },
        labelText: { margin: 4 },
        valueContainer: {
          display: "flex",
          flex: 2,
          justifyContent: "flex-start",
          alignItems: "center",
        },
        valueText: { margin: 2, color: primary, justifyContent: "flex-start" },
      }
    : {
        container: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
        },
        labelContainer: { display: "flex", flex: 1 },
        labelText: { margin: 0 },
        valueContainer: {
          display: "flex",
          flex: 2,
          justifyContent: "flex-start",
        },
        valueText: {
          marginVertical: 2,
          color: primary,
          justifyContent: "flex-start",
        },
      };
  return (
    <div style={getStyles.container}>
      <div style={getStyles.labelContainer}>
        <span style={getStyles.labelText}>{label}</span>
      </div>
      <div style={getStyles.valueContainer}>
        <span style={getStyles.valueText}>{value}</span>
      </div>
    </div>
  );
};

export default LabelWithValue;
