import { Input as MUIInput } from "@mui/material";

const Input = ({ onChange, placeholder, ...props }) => {
  return (
    <MUIInput
      onChange={onChange}
      placeholder={placeholder}
      color="primary"
      {...props}
    />
  );
};

export default Input;
