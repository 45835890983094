import { fetchGetApi, fetchGetApiNoAuth, fetchPostApi } from "./fetch";
export const createPayment = async (userId, ammount, paymentData) => {
  const { file, passengerId, concept, fileType } = paymentData;
  const bodyData = {
    ammount: ammount,
    imageUrl: file,
    passengerId: passengerId,
    fileType: fileType,
    currency: "usd",
  };
  const res = await fetchPostApi(`/users/${userId}/payment`, bodyData);
  return res;
};

export const getPaymentHistory = async (passengerId) => {
  const res = await fetchGetApi(`/passengers/${passengerId}/payment`);
  return res;
};

export const getPaymentById = async (id) => {
  const res = await fetchGetApiNoAuth(`/payments/${id}`);
  return res;
};
