import {
  ADD_PASSENGER,
  ADD_GROUP_TO_PASSENGER,
  EDIT_PASSENGER,
  CLEAN_PASSENGERS,
} from "../actionTypes";

const initialState = {
  passengers: null,
};

const passengersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PASSENGER:
      return {
        ...state,
        passengers: action.payload,
      };
    case ADD_GROUP_TO_PASSENGER:
      return {
        ...state,
      };
    case EDIT_PASSENGER:
      const passenger = state.passengers.find(
        (p) => p.id === action.payload.id
      );
      let newPassenger;
      if (passenger) {
        newPassenger = Object.assign(passenger, action.payload);
      } else {
        newPassenger = action.payload;
      }
      return { ...state, newPassenger };
    case CLEAN_PASSENGERS:
      return { passengers: null };
    default:
      return state;
  }
};

export default passengersReducer;
