import axios from "axios";

export default async function getDailyCurrency() {
  try {
    const res = await axios.get(
      "https://cotizaciones-brou-v2-e449.fly.dev/currency/latest"
    );
    const dollarsToUy = res.data?.rates?.USD?.buy;
    const date = res.data?.dateISOString;
    return {
      exchange: dollarsToUy,
      date: date,
    };
  } catch (e) {
    console.log("Error getting currencies,", e);
  }
}
