import { Divider } from "@mui/material";
import { useState, useEffect } from "react";
import useMobile from "../../hooks/useOrientation";
import { getUserPaymentDetails } from "../../services/userService";
import PaymentPlanTable from "./PaymentPlanTable";
import { LabelWithValue } from "../../ui";
import { CenteredLoader } from "../shared/CenteredLoader";
import { warning } from "../../ui/colors";

export const PassengerTripDetails = ({ passenger, setFinishedLoading }) => {
  const [data, setData] = useState();
  const { documentId, isActive } = passenger;
  const isMobile = useMobile();

  useEffect(() => {
    getUserPaymentDetails(documentId).then((res) => {
      setData(res);
      setFinishedLoading(true);
    });
  }, []);

  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      flex: isMobile ? 0 : 1,
    },
    sectionTitle: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  };
  return (
    <div style={styles.mainContainer}>
      {data ? (
        <>
          <TripSummary data={{ ...data, isActive: isActive }}></TripSummary>
          <Divider />
          {data.totalPendingToPay > 0 ? (
            <PaymentPlanTable payments={data.payments}></PaymentPlanTable>
          ) : null}
          <Divider />
        </>
      ) : (
        <CenteredLoader />
      )}
    </div>
  );
};

const TripSummary = ({ data }) => {
  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      {!data.isActive && (
        <h3 style={{ color: warning, marginBottom: 10 }}>
          Atencion! El pasajero ha sido dado de baja del viaje.
        </h3>
      )}
      <h4>
        <LabelWithValue label={"Pasajero:"} value={data.userName} />
        <LabelWithValue label={"Viaje:"} value={data.tripName} />

        <LabelWithValue
          label={"Precio total:"}
          value={data.totalPrice + " U$S"}
        />
        <LabelWithValue
          label={"Saldo:"}
          value={
            data?.totalPendingToPay > 0
              ? data?.totalPendingToPay + " U$S"
              : "Plan de pagos completado."
          }
        />
      </h4>
    </div>
  );
};
