import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { warning } from "../../ui/colors";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import Modal from "react-modal";

library.add(faCheck);
library.add(faClock);

const PaymentPlanTable = ({ payments }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function processDate(date) {
    const splittedDate = date.split(" ");
    if (splittedDate.length === 3) {
      return splittedDate[0];
    } else {
      return date;
    }
  }

  const isLatePayment = (date) => {
    const parseDate = (date) => {
      const parts = date.split("/");
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are 0-based (January is 0)
        const year = parseInt(parts[2], 10);

        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          return new Date(year, month, day);
        }
      }
    };
    return new Date() > parseDate(date);
  };

  const goToPayments = () => {
    navigate("/pagar");
  };

  return (
    <div style={{ gap: 10 }}>
      {payments ? (
        payments.length > 0 ? (
          <>
            <h3
              style={{
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              Siguientes pagos
            </h3>
            <Table size="small" aria-label="simple table">
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "70%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <strong>Descripción</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Fecha</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Monto (U$S)</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={index === 0 ? () => setIsModalOpen(true) : null}
                    style={{
                      backgroundColor:
                        index === 0 ? "rgba(0, 210, 182, 0.5)" : "transparent",
                      cursor: index === 0 ? "pointer" : null,
                    }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      className="tableItem"
                    >
                      {row.reference}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {processDate(row.maxDate)}{" "}
                      {isLatePayment(row.maxDate) ? (
                        <FontAwesomeIcon
                          icon={faClock}
                          color={warning}
                        ></FontAwesomeIcon>
                      ) : null}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.pendingToPay}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <h3>Has completado tu plan de pagos.</h3>
          </div>
        )
      ) : (
        <CircularProgress></CircularProgress>
      )}
      {isModalOpen ? (
        <ConfirmationModal
          label={"Pagar cuota?"}
          onCloseModal={() => setIsModalOpen(false)}
          onNavigate={() => goToPayments()}
        ></ConfirmationModal>
      ) : null}
    </div>
  );
};
export default PaymentPlanTable;

export const ConfirmationModal = ({ label, onCloseModal, onNavigate }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      style={customStyles}
      contentLabel="Example Modal"
      isOpen={true}
      ariaHideApp={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <span>{label}</span>
        <div style={{ display: "flex" }}>
          <Button onClick={() => onCloseModal()}>NO</Button>
          <Button onClick={() => onNavigate()}>SI</Button>
        </div>
      </div>
    </Modal>
  );
};
