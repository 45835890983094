import { CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { processErrorResponse } from "../../../helpers/processErrorResponse";
import useMobile from "../../../hooks/useOrientation";
import { addPassenger } from "../../../services/passengerService";
import { addUser } from "../../../services/userService";
import TermsAndConditions from "./TermsAndConditions";
import { LabelWithValue, Button } from "../../../ui";
import AddSignModal from "../../payment/AddSingModal";
import { image } from "@cloudinary/url-gen/qualifiers/source";
import { SessionContext } from "../../../helpers/session";

const RegistrationFinished = ({
  responsibleData,
  passengerData,
  tripData,
  onGoBack,
  isGrown,
  setUserRegisteredId,
  userRegisteredId,
}) => {
  const { user } = useContext(SessionContext);
  const [formSubmitted, setFormSubmitted] = useState();
  const isMobile = useMobile();
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [showSingModal, setShowSignModal] = useState(false);
  const [paymentData, setPaymentData] = useState({
    ammount: null,
    imageUrl: null,
    fileType: null,
  });

  const handleSubmitForm = async () => {
    if (!termsAndConditionsAccepted) {
      setErrorMessage(
        "Debes aceptar los terminos y condiciones del viaje para completar el registro."
      );
      return;
    }
    setLoading(true);
    const userData = {
      name: responsibleData.name,
      lastName: responsibleData.lastName,
      email: responsibleData.email.toLowerCase(),
      password: "password",
      phone: responsibleData.phone,
    };
    if (!user) {
      try {
        let userId;
        if (!userRegisteredId) {
          const userRes = await addUser(userData);
          setUserRegisteredId(userRes.id);
          userId = userRes.id;
        } else {
          userId = userRegisteredId;
        }
        const { ammount, fileType, imageUrl } = paymentData;
        await addPassenger(
          {
            ...passengerData,
            userId: userId,
            ammount: ammount,
            imageUrl: imageUrl,
            fileType: fileType,
          },
          tripData.group
        );
        setFormSubmitted(true);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("error", e);
        setErrorMessage("Error creando pasajero. Intentelo nuevamente.");
      }
    } else {
      //si el usuario ya existe
      try {
        await addPassenger(
          { ...passengerData, userId: user.id },
          tripData.group
        );
        setFormSubmitted(true);

        setLoading(false);
      } catch (e) {
        setLoading(false);
        setErrorMessage(processErrorResponse(e)[1]);
      }
    }
  };

  const handleOpenTermsAndConditions = () => {
    window.open("/terminosycondiciones");
  };

  const handleSetPaymentData = (data) => {
    const { file, ammount } = data;
    const fileType = file.type;
    const imageUrl = file.file;
    setPaymentData({
      fileType: fileType,
      imageUrl: imageUrl,
      ammount: ammount,
    });
    setShowSignModal(false);
  };

  const styles = {
    mobileContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    desktopContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
    },
    desktopRow: {
      flex: 1,
      paddingLeft: 10,
    },
    registerButton: {
      width: 300,
      alignItems: "center",
      alignSelf: "center",
    },
    paymentUploadedLabel: {
      alignItems: "center",
      alignSelf: "center",
    },
    loadingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    termsAndConditionsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    registerFinishedContainer: { textAlign: "center", marginBottom: 20 },
    registerFinishWrapper: {
      display: "flex",
      alignSelf: "center",
      flexDirection: "column",
      justifyContent: "center",
      gap: 15,
    },
  };

  const handleSeePayment = () => {
    window.open(paymentData.imageUrl);
  };

  return (
    <>
      {formSubmitted ? (
        <RegisterSuccess
          name={passengerData.name}
          trip={tripData.programName + " - " + tripData.tripName}
        />
      ) : (
        <div style={styles.registerFinishWrapper}>
          <h2 style={styles.registerFinishedContainer}>
            Registro completado, verifique sus datos:
          </h2>
          <div
            style={isMobile ? styles.mobileContainer : styles.desktopContainer}
          >
            <div style={!isMobile ? styles.desktopRow : null}>
              {!isGrown ? (
                <>
                  <h3 style={{ marginBottom: 10 }}>Datos madre/padre: </h3>
                  <LabelWithValue
                    label={"Nombre:"}
                    value={responsibleData.name}
                  />
                  <LabelWithValue
                    label={"Apellido:"}
                    value={responsibleData.lastName}
                  />
                  <LabelWithValue
                    label={"Telefono:"}
                    value={responsibleData.phone}
                  />
                  <LabelWithValue
                    label={"Email:"}
                    value={responsibleData.email.toLowerCase()}
                  />
                </>
              ) : null}
            </div>
            <div style={!isMobile ? styles.desktopRow : null}>
              <h3 style={{ marginBottom: 10 }}>Datos del pasajero/a:</h3>
              <LabelWithValue label={"Nombre:"} value={passengerData.name} />
              <LabelWithValue
                label={"Apellido:"}
                value={passengerData.lastName}
              />
              <LabelWithValue
                label={"Cedula:"}
                value={passengerData.documentId}
              />
              <LabelWithValue label={"Celular:"} value={passengerData.phone} />
              <LabelWithValue
                label={"Fecha de nacimiento:"}
                value={passengerData.birthDate}
              />
              <LabelWithValue
                label={"Viaje:"}
                value={tripData.programName + " - " + tripData.tripName}
              />
              <LabelWithValue label={"Grupo:"} value={tripData.groupName} />
            </div>
          </div>
          <div style={styles.termsAndConditionsContainer}>
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <Checkbox checked={termsAndConditionsAccepted}></Checkbox>
              }
              onClick={() =>
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
              }
            ></FormControlLabel>
            <span>
              Acepto los{" "}
              <span
                onClick={handleOpenTermsAndConditions}
                style={{ textDecoration: "underline" }}
              >
                Términos y condiciones del viaje
              </span>
            </span>
          </div>
          {showSingModal && (
            <AddSignModal
              onCloseModal={() => setShowSignModal(false)}
              paymentData={paymentData}
              onSendPaymentData={handleSetPaymentData}
            />
          )}
          {paymentData?.imageUrl && (
            <div style={{ display: "flex", alignSelf: "center" }}>
              <span style={styles.paymentUploadedLabel}>
                Comprobante subido correctamente.
              </span>
              <Button onClick={handleSeePayment}>VER ARCHIVO</Button>
            </div>
          )}
          {/* <Button
            onClick={() => setShowSignModal(true)}
            variant={"outlined"}
            customStyles={styles.registerButton}
          >
            {paymentData?.imageUrl
              ? "Editar comprobante."
              : "Ya señaste? Subí el comprobante."}
          </Button> */}
          {loading ? (
            <div style={styles.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              disabled={!termsAndConditionsAccepted}
              variant="contained"
              onClick={handleSubmitForm}
              customStyles={styles.registerButton}
            >
              FINALIZAR REGISTRO
            </Button>
          )}

          <h5 style={{ textAlign: "center" }}>{errorMessage}</h5>
          <Button onClick={onGoBack}>Volver</Button>
        </div>
      )}
    </>
  );
};

export default RegistrationFinished;

const RegisterSuccess = ({ name, trip }) => {
  const { user } = useContext(SessionContext);
  const plane = require("../../../assets/plane.png");
  const navigate = useNavigate();

  const handleGoToMyTrips = () => {
    window.location.href = "./perfil";
  };

  const styles = {
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    planeImg: {
      width: 100,
      marginTop: 20,
    },
  };
  return (
    <div style={styles.container}>
      <h1>Registro completado!</h1>
      <h4 style={{ textAlign: "center" }}>
        Felicitaciones {name}, tu viaje a {trip} está cada vez más cerca!
      </h4>
      {!user ? (
        <div style={styles.infoContainer}>
          <img src={plane} alt="" style={styles.planeImg}></img>
          <h4 style={{ textAlign: "center", marginTop: 40 }}>
            Si llegaste hasta acá, estás por buen camino! Te vamos a acompañar
            durante el viaje y después seguiremos juntos. <br></br>Porque
            College es más que un viaje.
          </h4>
          <p style={{ textAlign: "center" }}>
            Cuando los datos sean validados te enviaremos un mail con tu usuario
            y contraseña para ingresar al sistema.
          </p>
          <Button onClick={() => navigate("/login")} variant="contained">
            TERMINAR
          </Button>
        </div>
      ) : (
        <Button onClick={() => handleGoToMyTrips()}>IR A MIS VIAJES</Button>
      )}
    </div>
  );
};
