import React, { useEffect, useState } from "react";
import { TextField, Button, InputLabel } from "@mui/material";
import { NAME, EMAIL, PHONE_NUMBER } from "../../../helpers/maxLenghts";
import FileUploader from "../../shared/FileUploader";
import AddSignModal from "../../payment/AddSingModal";
import { primary } from "../../../ui/colors";
import SelectComponent from "../../shared/SelectComponent";
import backgroundMobile from "../../../assets/AlbumMobileSecond.jpg";
import backgroundDesktop from "../../../assets/AlbumDesktopSecond.jpg";
import useMobileDetector from "../../../hooks/useMobileDetector";

const MARGIN_BOTTOM = 8;
const USD = "usd";

const image = {
  width: "100%",
  height: "auto",
};

const Second = ({ data, onChangeData, schools }) => {
  const [selected, setSelected] = useState(2);
  const isMobile = useMobileDetector();
  const styles = {
    cardsContainer: {
      display: "flex",
      flex: 1,
      height: 100,
      flexDirection: "row",
      gap: 20,
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      border: "1px",
      height: 200,
      border: "1px solid transparent", // Ensure border is always present
      borderRadius: 10,
      borderWidth: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
  };

  const albumOptions = [
    {
      id: 0,
      value: 0,
      name: "Álbum chico",
    },
    {
      id: 1,
      value: 1,
      name: "Álbum grande",
    },
  ];
  useEffect(() => {
    onChangeData({ ...data, ["isAlbum1"]: selected === 1 });
  }, [selected]);

  const formatSchools = () => {
    const formattedSchools = [];

    for (const key in schools) {
      if (schools.hasOwnProperty(key)) {
        formattedSchools.push({
          id: key.trim(),
          value: key.trim(),
          name: key.trim(),
        });
      }
    }
    formattedSchools.sort((a, b) => a.id.localeCompare(b.id));
    return formattedSchools;
  };

  const handleChangeSchool = (val) => {
    const schoolValue = val.value;
    const busNumber = schools[schoolValue];
    onChangeData({
      ...data,
      ["NightNumber"]: busNumber,
      ["HighSchool"]: schoolValue,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <img
        src={isMobile ? backgroundMobile : backgroundDesktop}
        style={image}
      ></img>
      <div style={styles.formContainer}>
        <h3 className="verticalPaddingText">Completar datos de reserva:</h3>
        <SelectComponent
          onChange={(val) => setSelected(val.value)}
          label={"Elegir álbum"}
          data={albumOptions}
          val={selected}
        ></SelectComponent>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.ParentName}
            onChange={(e) =>
              onChangeData({ ...data, ["ParentName"]: e.target.value })
            }
            placeholder="Nombre padre/madre/tutor"
            inputProps={{
              maxLength: NAME,
              style: { textTransform: "capitalize" },
            }}
          ></TextField>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.ParentLastName}
            onChange={(e) =>
              onChangeData({ ...data, ["ParentLastName"]: e.target.value })
            }
            placeholder="Apellido padre/madre/tutor"
            inputProps={{
              maxLength: NAME,
              style: { textTransform: "capitalize" },
            }}
          ></TextField>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.ParentPhone}
            onChange={(e) =>
              onChangeData({ ...data, ["ParentPhone"]: e.target.value })
            }
            placeholder="Celular padre/madre/tutor"
            inputProps={{ maxLength: PHONE_NUMBER }}
          ></TextField>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.PassengerName}
            onChange={(e) =>
              onChangeData({ ...data, ["PassengerName"]: e.target.value })
            }
            placeholder="Nombre pasajero/a"
            inputProps={{
              maxLength: NAME,
              style: { textTransform: "capitalize" },
            }}
          ></TextField>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.PassengerLastName}
            onChange={(e) =>
              onChangeData({ ...data, ["PassengerLastName"]: e.target.value })
            }
            placeholder="Apellido pasajero/a"
            inputProps={{
              maxLength: NAME,
              style: { textTransform: "capitalize" },
            }}
          ></TextField>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <TextField
            style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
            value={data.PassengerDocumentId}
            onChange={(e) =>
              onChangeData({ ...data, ["PassengerDocumentId"]: e.target.value })
            }
            placeholder="Cédula pasajero/a"
            inputProps={{ maxLength: PHONE_NUMBER }}
          ></TextField>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <div style={{ flex: 3 }}>
            <SelectComponent
              onChange={(val) => handleChangeSchool(val)}
              label={"Grupo del pasajero/a"}
              data={formatSchools()}
              val={data.HighSchool}
            ></SelectComponent>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              disabled
              style={{ marginBottom: MARGIN_BOTTOM, flex: 1 }}
              value={"Coche " + data.NightNumber}
              onChange={(e) =>
                onChangeData({ ...data, ["NightNumber"]: e.target.value })
              }
              placeholder="Coche"
              inputProps={{ maxLength: 1 }}
            ></TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
