export const traduceState = (state, imageUrl) => {
  if (imageUrl === "Abitab") {
    return ["Abitab", false];
  } else {
    return getStatusString(state);
  }
};

const getStatusString = (state) => {
  switch (state) {
    case "Approved":
      return ["Aprobado", true];
    case "Declined":
      return ["Declinado", false];
    case "Pending confirmation":
      return ["Pendiente de aprobación", false];
    case "Delete":
      return ["Cancelado", false];
    case "Rifa":
      return ["Rifas", false];
    default:
      return ["", false];
  }
};
