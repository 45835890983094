import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectComponent from "../../shared/SelectComponent";
import { getPrograms } from "../../../services/programService";
import { processErrorResponse } from "../../../helpers/processErrorResponse";
import { getAllNamesByProgramId } from "../../../services/groupService";
import { getAllByProgramIdAndGroupName } from "../../../services/tripsService";
const SelectTrip = ({ data, onChangeData }) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const [programs, setPrograms] = useState();
  const [trips, setTrips] = useState();
  const [groups, setGroups] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessage("");
    setLoading(true);
    getPrograms().then((res) => {
      let tripsRes = [];
      res.forEach((element) => {
        let ele = {
          id: element.id,
          name: element.name,
        };
        tripsRes.push(ele);
      });
      setPrograms(tripsRes);

      if (data.program) {
        getAllNamesByProgramId(data.program)
          .then((res) => {
            let renamedArray = res.map((obj, id) => {
              let newObj = { ...obj };
              newObj.name = newObj.groupName;
              newObj.id = newObj.groupName;
              delete newObj.groupName;
              return newObj;
            });
            setGroups(renamedArray);
            if (data.groupName) {
              getAllByProgramIdAndGroupName(data.program, data.groupName)
                .then((res) => {
                  let renamedArray = res.map((obj, id) => {
                    let newObj = { ...obj };
                    newObj.name = newObj.tripName;
                    newObj.id = newObj.tripId;
                    newObj.groupId = newObj.groupId;
                    delete newObj.groupName;
                    delete newObj.tripName;
                    delete newObj.tripId;
                    return newObj;
                  });
                  setTrips(renamedArray);
                  setLoading(false);
                })
                .catch((e) => console.log(e))
                .finally(() => setLoading(false));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleChangeProgram = (val) => {
    onChangeData({
      ...data,
      ["program"]: val.id,
      ["programName"]: val.name,
      ["groupName"]: null,
      ["trip"]: null,
      ["trip"]: null,
      ["group"]: null,
    });
    setMessage("");
    setLoading(true);
    getAllNamesByProgramId(val.id)
      .then((res) => {
        let renamedArray = res.map((obj, id) => {
          let newObj = { ...obj };
          newObj.name = newObj.groupName;
          newObj.id = newObj.groupName;
          delete newObj.groupName;
          return newObj;
        });
        setGroups(renamedArray);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeGroup = (val) => {
    setLoading(true);
    onChangeData({
      ...data,
      ["groupName"]: val.id,
      ["trip"]: val.id,
      ["tripName"]: val.name,
    });

    getAllByProgramIdAndGroupName(data.program, val.id)
      .then((res) => {
        let renamedArray = res.map((obj, id) => {
          let newObj = { ...obj };
          newObj.name = newObj.tripName;
          newObj.id = newObj.tripId;
          newObj.groupId = newObj.groupId;
          delete newObj.groupName;
          delete newObj.tripName;
          delete newObj.tripId;
          return newObj;
        });
        setTrips(renamedArray);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeTrip = async (val) => {
    onChangeData({
      ...data,
      ["trip"]: val.id,
      ["tripName"]: val.name,
      ["group"]: val.groupId,
    });
  };

  const styles = {
    title: {
      marginBottom: 20,
    },
  };

  return (
    <div>
      <h3 style={styles.title}>Elegir viaje al que desea inscribirse.</h3>
      <SelectComponent
        onChange={(val) => handleChangeProgram(val)}
        label={"Elegir viaje"}
        val={data.program}
        data={programs}
        disabled={loading}
      ></SelectComponent>
      <SelectComponent
        onChange={(val) => handleChangeGroup(val)}
        label={"Elegir grupo"}
        val={data.groupName}
        data={groups}
        disabled={loading}
      ></SelectComponent>
      <SelectComponent
        onChange={(val) => handleChangeTrip(val)}
        label={"Elegir programa"}
        val={data.trip}
        data={trips}
        disabled={loading}
      ></SelectComponent>
      {data.trip && (
        <span>
          Comunicarse con College en caso de querer realizar modificaciones en
          el programa.
        </span>
      )}
      <h3>{message}</h3>
    </div>
  );
};

export default SelectTrip;
