import React, { useEffect, useState } from "react";
import {
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { primary } from "../../ui/colors";

const PaymentFeesSelector = ({ paymentFees, onChangeFees }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectFee = (item) => {
    setSelectedItems(
      selectedItems.includes(item)
        ? selectedItems.filter((i) => i !== item)
        : [...selectedItems, item]
    );
  };

  useEffect(() => {
    let value = 0;
    let concept = "";
    selectedItems.forEach((i) => {
      value += paymentFees[i].pendingToPay;
      concept += paymentFees[i].reference + " ";
    });
    onChangeFees(value, concept);
  }, [selectedItems]);
  return (
    <div>
      <InputLabel id="demo-simple-select-label" style={{ marginTop: "20px" }}>
        <span>Siguientes pagos:</span>
      </InputLabel>
      <FormGroup>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 10,
            border: "1px solid black",
            borderRadius: 5,
            borderColor: primary,
            borderWidth: 2,
            gap: 10,
            width: 300,
          }}
        >
          {paymentFees &&
            paymentFees.map((p, index) => (
              <p style={{ margin: 0 }}>
                {p.reference +
                  ": " +
                  p.pendingToPay +
                  " U$S." +
                  (index === 0 ? " Vto: " + p.maxDate : "")}
              </p>
            ))}
        </div>
      </FormGroup>
    </div>
  );
};

export default PaymentFeesSelector;
