import axios from "axios";
import {
  fetchGetApi,
  fetchPostApi,
  fetchPostApiNoAuth,
  fetchPutApi,
} from "./fetch";

export const login = async (user, password) => {
  const res = await fetchPostApiNoAuth("/users/login", {
    email: user,
    password: password,
  });
  return res;
};

export const changePassword = async (userId, data) => {
  const res = await fetchPostApiNoAuth(`/users/${userId}`, data);
  return res;
};

export const addUser = async (user) => {
  const bodyData = {
    name: user.name,
    lastName: user.lastName,
    email: user.email,
    role: "User",
    phone: user.phone,
    password: user.phone,
  };
  const res = await fetchPostApi("/users", bodyData);
  return res;
};

export const getUserTrips = async (id) => {
  const res = await fetchGetApi(`/users/${id}/trips`);
  return res;
};

export const editUserPassword = async (id, user) => {
  const bodyData = {
    password: user.password,
    oldPassword: user.oldPassword,
    phone: user.phone,
  };
  const res = await fetchPutApi(`/users/${id}`, bodyData);
  return res;
};

export const recoverPasswordSendEmail = async (email) => {
  const bodyData = {
    email: email,
  };
  const res = fetchPostApi(`/users/recover`, bodyData);
  return res;
};

export const recoverPasswordSendNewPassword = async (key, password) => {
  const bodyData = {
    key: key,
    password: password,
  };
  const res = await fetchPutApi(`/users/recover`, bodyData);
  return res;
};

export const editUser = async (userId, email, phone) => {
  const bodyData = {
    email: email,
    phone: phone,
  };
  const res = await fetchPutApi(`/users/${userId}`, bodyData);
  return res;
};

export const getUserPaymentDetails = async (passengerCI) => {
  const res = await fetchGetApi(`/passengers/${passengerCI}/paymentsPlans`);
  return res;
};
