import axios from "axios";
import { cleanSessionCookie } from "../helpers/session";
import store from "../store";
import api from "./api";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function fetchGetApi(url) {
  const res = await api.get(API_BASE_URL + url);
  return res.data;
}

export async function fetchPostApi(url, body) {
  const res = await api.post(API_BASE_URL + url, body);
  return res.data;
}

export async function fetchGetApiNoAuth(url) {
  const res = await api.get(API_BASE_URL + url);
  return res.data;
}

export async function fetchPostApiNoAuth(url, body) {
  const res = await api.post(API_BASE_URL + url, body);
  return res.data;
}

export async function fetchPutApi(url, body) {
  const res = await api.put(url, body);
  return res.data;
}
