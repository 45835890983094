import React, { useContext, useState } from "react";
import Responsible from "./steps/Responsible";
import Passenger from "./steps/Passenger";
import SelectTrip from "./steps/SelectTrip";
import RegistrationFinished from "./steps/RegistrationFinished";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui";
import {
  ValidateCI,
  ValidateDate,
  ValidateEmail,
  ValidatePhone,
} from "../shared/regex";
import secureLocalStorage from "react-secure-storage";
import useMobile from "../../hooks/useOrientation";
import { Passports } from "./steps/Passports";
import ProgressBar from "@ramonak/react-progress-bar";
import { warning } from "../../ui/colors";
import { SessionContext } from "../../helpers/session";

const RegisterScreen = () => {
  const { user } = useContext(SessionContext);
  const id = user?.id;
  const [step, setStep] = useState(id ? 2 : 1);
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [userRegisteredId, setUserRegisteredId] = useState(
    secureLocalStorage.getItem("registeredUser")
  );

  const [errorMessage, setErrorMessage] = useState();
  const [passengerData, setPassengerData] = useState({
    name: "",
    lastName: "",
    phone: "",
    birthDate: "",
    documentId: "",
    email: "noesnecesario@gmail.com",
    highschool: "",
    address: "",
    department: "",
    //just for ui purposes
  });

  const [responsibleData, setResponsibleData] = useState({
    name: user?.name || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone || "",
  });

  const [tripData, setTripData] = useState({
    program: "",
    trip: "",
    group: "",
    programName: "",
    tripName: "",
    groupName: "",
  });

  const moveForward = () => {
    setErrorMessage("");
    if (step === 1) {
      const { name, lastName, email, phone } = responsibleData;
      if (
        stringNotEmpty(name) &&
        stringNotEmpty(lastName) &&
        stringNotEmpty(email) &&
        stringNotEmpty(phone)
      ) {
        if (!ValidatePhone(phone)) {
          setErrorMessage("Telefono incorrecto");
        } else if (!ValidateEmail(email)) {
          setErrorMessage("Email incorrecto");
        } else {
          setErrorMessage("");
          setStep(step + 1);
        }
      } else {
        setErrorMessage("Se deben completar todos los campos.");
      }
    } else if (step === 2) {
      const { name, lastName, phone, birthDate, documentId, highschool } =
        passengerData;
      if (
        stringNotEmpty(name) &&
        stringNotEmpty(lastName) &&
        stringNotEmpty(phone) &&
        stringNotEmpty(birthDate) &&
        stringNotEmpty(documentId) &&
        stringNotEmpty(highschool)
      ) {
        if (!ValidateDate(birthDate)) {
          setErrorMessage("Fecha de nacimiento incorrecta");
        } else if (!ValidatePhone(phone)) {
          setErrorMessage("Telefono incorrecto");
        } else if (!ValidateCI(documentId)) {
          setErrorMessage("Cedula  incorrecta");
        } else {
          setErrorMessage("");
          setStep(step + 1);
        }
      } else {
        setErrorMessage("Se deben completar todos los campos.");
      }
    } else if (step === 3) {
      const { program, trip, group } = tripData;
      if (
        stringNotEmpty(program) &&
        stringNotEmpty(trip) &&
        stringNotEmpty(group)
      ) {
        setStep(step + 1);
      } else {
        setErrorMessage("Se deben completar todos los campos.");
      }
    } else {
      setStep(step + 1);
    }
  };

  const stringNotEmpty = (word) => {
    return word;
  };

  const handleSetUserRegistered = (id) => {
    setUserRegisteredId(id);
    secureLocalStorage.setItem("registeredUser", id);
  };

  const getComponentStep = () => {
    switch (step) {
      case 1:
        return (
          <Responsible
            data={responsibleData}
            onChangeData={(data) => setResponsibleData(data)}
          />
        );
      case 2:
        return (
          <Passenger
            data={passengerData}
            onChangeData={(data) => setPassengerData(data)}
          />
        );
      case 3:
        return (
          <SelectTrip
            data={tripData}
            onChangeData={(data) => setTripData(data)}
          />
        );
      case 4:
        //   return <Passports data={tripData} />;
        // case 5:
        return (
          <RegistrationFinished
            responsibleData={responsibleData}
            passengerData={passengerData}
            tripData={tripData}
            onGoBack={() => setStep(1)}
            setUserRegisteredId={(id) => handleSetUserRegistered(id)}
            userRegisteredId={userRegisteredId}
          />
        );
      default:
    }
  };
  const isLastStep = step === 4;
  return (
    <div
      style={
        !isMobile
          ? {
              padding: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }
          : { padding: 10 }
      }
    >
      {!isLastStep && (
        <h1 style={{ textAlign: isMobile ? "center" : "flex-start" }}>
          Registro
        </h1>
      )}
      {!isLastStep && (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressBar
            className="progress"
            width="100%"
            bgColor={"#00D2B7"}
            completed={(step - 1) * 25}
          />
        </div>
      )}
      <div
        style={{
          marginTop: 20,
          width: isMobile ? "100%" : isLastStep ? "70%" : "50%",
        }}
      >
        {getComponentStep()}
      </div>
      <h4 style={{ color: warning }}>{errorMessage}</h4>
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!isLastStep ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {id
                  ? step !== 2
                  : step !== 1 && (
                      <Button
                        customStyles={{ margin: 5 }}
                        variant="contained"
                        onClick={() => setStep(step - 1)}
                      >
                        <h3 style={{ margin: 0 }}>Anterior</h3>
                      </Button>
                    )}

                <Button
                  customStyles={{
                    margin: 5,
                    alignSelf: isMobile ? "center" : null,
                  }}
                  variant="contained"
                  onClick={moveForward}
                >
                  <h3 style={{ margin: 0 }}>Siguiente</h3>
                </Button>
              </div>
              {user ? (
                <Button onClick={() => navigate("/perfil")}>
                  Ir al perfil
                </Button>
              ) : (
                <Button onClick={() => navigate("/login")}>
                  Volver al login
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
