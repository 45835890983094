import React, { useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  recoverPasswordSendEmail,
  recoverPasswordSendNewPassword,
} from "../../services/userService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { Button } from "../../ui";
import useMobile from "../../hooks/useOrientation";

const RecoverPassword = () => {
  const isMobile = useMobile();

  const styles = {
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      width: isMobile ? "auto" : "100%",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    button: {
      width: 200,
      marginBottom: 15,
    },
    buttons: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      marginTop: 15,
    },
  };
  const defaultData = {
    email: "",
    password: "",
    passwordRepeat: "",
  };
  const [searchparams] = useSearchParams();
  const token = searchparams.get("r");
  const navigate = useNavigate();
  const [data, setData] = useState(defaultData);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const handleSendEmail = () => {
    setLoading(true);
    recoverPasswordSendEmail(data.email)
      .then((res) => {
        setEmailSent(true);
        setData(defaultData);
      })
      .catch((e) => setError(processErrorResponse(e)[1]))
      .finally(() => setLoading(false));
  };

  const handleChangePassword = () => {
    if (data.password.length > 3 && data.password === data.passwordRepeat) {
      setLoading(true);
      recoverPasswordSendNewPassword(token, data.password)
        .then((res) => {
          setSuccess(true);
          setError("");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    } else {
      setError("Las contraseñas no coinciden.");
    }
  };

  return (
    <div style={styles.container}>
      <h1>Recuperar contraseña</h1>
      {!token ? (
        !emailSent ? (
          <div style={styles.flexColumn}>
            <p>Ingrese su email para recuperar la contraseña.</p>
            <TextField
              onChange={(e) =>
                setData({
                  ...data,
                  ["email"]: e.target.value,
                })
              }
              placeholder="Email"
              style={{ margin: 10 }}
            ></TextField>
            <div style={styles.buttons}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => handleSendEmail()}
                style={styles.button}
              >
                ENVIAR
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4>
              Email enviado. Ingresa en el link que ha sido enviado a tu correo.
            </h4>
          </div>
        )
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}
        >
          <h3>Ingresar nueva contraseña.</h3>
          <TextField
            type="password"
            style={{ margin: 10 }}
            onChange={(e) => setData({ ...data, ["password"]: e.target.value })}
            placeholder={"Contraseña nueva"}
          />
          <TextField
            style={{ margin: 10 }}
            type="password"
            onChange={(e) =>
              setData({ ...data, ["passwordRepeat"]: e.target.value })
            }
            placeholder={"Repetir contraseña nueva"}
          />
          <Button
            variant="contained"
            onClick={() => handleChangePassword()}
            customStyles={{ marginTop: 10 }}
          >
            ENVIAR
          </Button>
        </div>
      )}
      {success && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <h4>Contraseña cambiada exitosamente!</h4>
        </div>
      )}
      {loading && <CircularProgress />}

      <h4 style={{ padding: 20 }}>{error}</h4>
      <Button onClick={() => navigate("/login")} style={styles.button}>
        Volver al login
      </Button>
    </div>
  );
};
export default RecoverPassword;
