export function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export function ValidatePhone(phone) {
  const regex = /^(\+598|0)[2-9]\d{7}$/;
  if (regex.test(phone)) {
    return true;
  }
  return false;
}

export function ValidatePassword(password) {
  return password.length > 7;
}

export function ValidatePaymentAmmount(ammount) {
  const regex = /^\d{2,7}$/;
  if (regex.test(ammount)) {
    return true;
  }
  return false;
}

export function ValidateCI(ci) {
  return ci.length === 8;
}

export function ValidateDate(date) {
  if (
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(date)
  ) {
    return true;
  }
  return false;
}
