import { ADD_USER, SET_AUTHENTICATED } from "../actionTypes";

const addUser = (user) => {
  return {
    type: ADD_USER,
    payload: user,
  };
};

const setAuthenticated = (isAuthenticated) => {
  return {
    type: SET_AUTHENTICATED,
    payload: isAuthenticated,
  };
};

export { addUser, setAuthenticated };
