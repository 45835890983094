export const ADD_USER = "ADD_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";

export const ADD_PASSENGER = "ADD_PASSENGER";
export const ADD_GROUP_TO_PASSENGER = "ADD_GROUP_TO_PASSENGER";
export const EDIT_PASSENGER = "EDIT_PASSENGER";
export const CLEAN_PASSENGERS = "CLEAN_PASSENGERS";

export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";

export const SWITCH_SHOW_NOTIFICATION = "SWITCH_SHOW_NOTIFICATION";
