import React, { useEffect, useState } from "react";
import { PickerOverlay } from "filestack-react";
import { Button } from "../../ui";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

library.add(faUpload);

const FILESTACK_API_KEY = process.env.REACT_APP_FILESTACK_API_KEY;

const FileUploader = ({ onFileUploaded, onFileSelected }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleUploadDone = (res) => {
    if (res.filesFailed.length > 0) {
      console.log("Error uploading files: ", res.filesFailed);
    }
    if (res.filesUploaded) {
      const url = res.filesUploaded[0].url;
      const isPdf = res.filesUploaded[0].mimetype.includes("pdf");
      const isImage =
        res.filesUploaded[0].mimetype.includes("jpeg") ||
        res.filesUploaded[0].mimetype.includes("png") ||
        res.filesUploaded[0].mimetype.includes("jpg");
      const fileType = isImage ? 0 : isPdf ? 1 : -1;
      onFileUploaded(url, fileType);
      setShowPicker(false);
    }
  };

  return (
    <div>
      <Button onClick={() => setShowPicker(true)}>
        <FontAwesomeIcon icon={faUpload} /> SUBIR COMPROBANTE
      </Button>
      {showPicker && (
        <PickerOverlay
          apikey={FILESTACK_API_KEY}
          pickerOptions={{
            onClose: () => {
              setShowPicker(false);
            },
            onCancel: () => {
              setShowPicker(false);
            },
            maxSize: 10000000,
          }}
          onUploadDone={handleUploadDone}
        />
      )}
    </div>
  );
};

export default FileUploader;
