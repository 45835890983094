import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleOverlay } from "../store/actions/notificationActions";
import { Button } from "../ui";
import image from "../assets/check.png";

export const OverlayNotification = () => {
  const { showNotification, text, success } = useSelector(
    (state) => state.notification
  );

  const dispatch = useDispatch();

  if (!showNotification) {
    return;
  }
  return (
    <div className="overlay">
      <div
        className="modal"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button onClick={() => dispatch(toggleOverlay({ show: false }))}>
          Cerrar
        </Button>
        {success ? (
          <img
            src={image}
            style={{ alignSelf: "center", width: 100 }}
            alt=""
          ></img>
        ) : null}
        <p style={{ textAlign: "center" }}>{text}</p>
      </div>
    </div>
  );
};
