import React, { useEffect } from "react";
import MenuAppBar from "./header";
import useMobile from "../hooks/useOrientation";
import { getSessionCookie } from "../helpers/session";

const ScreenWithHeader = ({ children }) => {
  const isMobile = useMobile();

  useEffect(() => {
    if (!getSessionCookie("user_session")) {
      window.location = "login";
    }
  }, []);

  if (!getSessionCookie("user_session")) {
    return;
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginBottom: 10,
      }}
    >
      <MenuAppBar></MenuAppBar>
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          paddingBottom: 0,
        }}
      >
        {children}
      </div>
      {!isMobile && (
        <img
          src="https://res.cloudinary.com/dyvyiepbv/image/upload/v1684895033/Screen_Shot_2023-05-23_at_19.22.22_xzbn5f.png"
          style={{
            width: "150px",
            alignSelf: "flex-end",
            marginBottom: 30,
            marginRight: 20,
          }}
        ></img>
      )}
    </div>
  );
};

export default ScreenWithHeader;
