import React from "react";
import { TextField } from "@mui/material";
import "../../../App.css";
import backgroundMobile from "../../../assets/AlbumMobileFirst.jpg";
import backgroundDesktop from "../../../assets/AlbumDesktopFirst.jpg";
import useMobileDetector from "../../../hooks/useMobileDetector";

const styles = {};
const MARGIN_BOTTOM = 8;

const First = ({ data, onChangeData }) => {
  const isMobile = useMobileDetector();
  const backgroundImageMobile = {
    backgroundImage: `url(${backgroundMobile})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
  };
  const backgroundImagDesktop = {
    backgroundImage: `url(${backgroundDesktop})`,
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
  };

  return (
    <div style={isMobile ? backgroundImageMobile : backgroundImagDesktop}></div>
  );
};

export default First;
