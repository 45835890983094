import { SWITCH_SHOW_NOTIFICATION } from "../actionTypes";

const initialState = {
  showNotification: false,
  text: "",
  success: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action.payload.show,
        text: action.payload.text,
        success: action.payload.success,
      };
    default:
      return state;
  }
};

export default notificationReducer;
