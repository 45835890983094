import { CircularProgress } from "@mui/material";
import useMobile from "../../hooks/useOrientation";
export const CenteredLoader = () => {
  const isMobile = useMobile();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        textAlign: "center",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <CircularProgress />
    </div>
  );
};
