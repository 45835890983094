import { useEffect, useState } from "react";

export default function useMobile() {
  const [mobile, setMobile] = useState(window.screen.width < 600);

  useEffect(() => {
    function handleResizeScreen() {
      const width = window.screen.width;
      setMobile(width < 600);
    }
    window.addEventListener("resize", handleResizeScreen);
  });

  return mobile;
}
