import React, { useState } from "react";
import Modal from "react-modal";
import { CircularProgress, Input } from "@mui/material";
import { Button } from "../../ui";
import { editUserPassword } from "../../services/userService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { ValidatePassword } from "../shared/regex";

const ChangePasswordModal = ({ onCloseModal, user }) => {
  const [data, setData] = useState({
    email: user.email,
    phone: user.phone,
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    inputs: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    inputStyles: { marginTop: 10, marginBottom: 10, width: "100%" },
  };

  const handleChangePassword = () => {
    if (data.newPassword !== data.newPasswordRepeat) {
      setMessage("Las contraseñas no coinciden.");
    } else if (!ValidatePassword(data.newPassword)) {
      setMessage(
        "La nueva contraseña debe tener al menos 8 caracteres de largo."
      );
    } else {
      setLoading(true);
      editUserPassword(user.id, {
        password: data.newPassword,
        oldPassword: data.oldPassword,
        phone: user.phone,
      })
        .then((res) => {
          setMessage("Contraseña cambiada correctamente.");
          setData({
            ...data,
            ["oldPassword"]: "",
            ["newPassword"]: "",
            ["newPasswordRepeat"]: "",
          });
          setSuccess(true);
        })
        .catch((e) => {
          setMessage(processErrorResponse(e)[1]);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      style={customStyles}
      contentLabel="Example Modal"
      isOpen={true}
      ariaHideApp={false}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button onClick={() => onCloseModal()}>Cerrar</Button>

        {!success ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Cambiar contraseña</h2>
            <span style={{ marginTop: 10, marginBottom: 10 }}>
              Su contraseña es insegura, recomendamos cambiarla por motivos de
              seguridad.
            </span>
            <div style={customStyles.inputs}>
              <Input
                type="password"
                style={{ marginTop: 10 }}
                onChange={(e) =>
                  setData({ ...data, ["oldPassword"]: e.target.value })
                }
                placeholder={"Contraseña anterior"}
              ></Input>
              <Input
                type="password"
                style={{ marginTop: 10 }}
                onChange={(e) =>
                  setData({ ...data, ["newPassword"]: e.target.value })
                }
                placeholder={"Contraseña nueva"}
              />
              <Input
                style={customStyles.inputStyles}
                type="password"
                onChange={(e) =>
                  setData({ ...data, ["newPasswordRepeat"]: e.target.value })
                }
                placeholder={"Repetir contraseña nueva"}
              ></Input>
              {!success ? (
                <Button
                  style={{ marginTop: 15 }}
                  variant="contained"
                  onClick={handleChangePassword}
                >
                  CAMBIAR contraseña
                </Button>
              ) : null}
              <p>{message}</p>
              {loading && (
                <div style={{ alignSelf: "center" }}>
                  <CircularProgress></CircularProgress>
                </div>
              )}
            </div>
          </div>
        ) : (
          <span>Contraseña cambiada correctamente.</span>
        )}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
