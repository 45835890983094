import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "../../ui/index";
import { traduceState } from "../../helpers/traduceState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import useMobile from "../../hooks/useOrientation";
import { encrypted } from "../shared/encrypt";

library.add(faCircleQuestion);

const APP_BASE_URL = process.env.REACT_APP_APP_URL;

const PaymentHistory = ({ paymentHistory }) => {
  const isMobile = useMobile();
  const [payments, setPayments] = useState();
  useEffect(() => {
    // Inicializar el objeto acumulado
    let rifaObj = null;
    let totalAmmount = 0;
    paymentHistory?.forEach((payment) => {
      if (payment.concept.includes("Rifa")) {
        totalAmmount += payment.ammount;

        // Mantener las claves/valores de la última rifa
        rifaObj = { ...payment, status: "Rifa" };
      }
    });
    // Si se encontraron rifas, actualizar el ammount total en el objeto
    if (rifaObj) {
      rifaObj.ammount = totalAmmount;
    }

    // Filtrar las transacciones que no sean de rifa
    const nonRifaTransactions = paymentHistory.filter(
      (transaction) => !transaction.concept.includes("Rifa")
    );

    // Añadir el objeto de rifa si existe
    let finalTransactions = rifaObj
      ? [...nonRifaTransactions, rifaObj]
      : nonRifaTransactions;

    setPayments(finalTransactions);
  }, []);

  const styles = {
    section: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      paddingTop: 10,
      paddingBottom: 10,
    },
    tableContainer: {
      width: isMobile ? "100%" : "auto",
      backgroundColor: "transparent",
      overflow: "scroll",
      tableLayout: "fixed",
    },
    sectionTitle: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    noPaymentsYet: {
      marginTop: 15,
    },
  };
  return (
    <div style={styles.section}>
      <h3 style={styles.sectionTitle}>Historial de pagos</h3>
      {payments && payments.length > 0 ? (
        <TableContainer component={Paper} style={styles.tableContainer}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center">Concepto</TableCell> */}
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Monto</TableCell>
                <TableCell align="center">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell align="center" component="th" scope="row">
                    {row.concept}
                  </TableCell> */}
                  <TableCell align="center" component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.ammount} U$S
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <span>{traduceState(row.status, row.imageUrl)[0]}</span>
                    {row.status === "Declined" && (
                      <div style={{ paddingLeft: 5 }}>
                        <Tooltip
                          title={("MOTIVO DE CANCELACION: ", row.comments)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                        </Tooltip>
                      </div>
                    )}
                    {row.status === "Pending confirmation" && (
                      <div style={{ paddingLeft: 5 }}>
                        <Tooltip
                          title={
                            "Estamos verificando la acreditación del dinero en nuestra cuenta."
                          }
                        >
                          <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.status === "Approved" && (
                      <>
                        <Button
                          onClick={() =>
                            window.open(
                              `${APP_BASE_URL}/recibo/${encrypted(row.id)}`
                            )
                          }
                        >
                          VER RECIBO
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span style={styles.noPaymentsYet}>
          No has realizado ningun pago hasta la fecha.
        </span>
      )}
    </div>
  );
};

export default PaymentHistory;
