import React, { useState, useEffect, useContext } from "react";
import ChangePasswordModal from "./ChangePassword";
import EditUserDataModal from "./EditUserDataModal";
import { useNavigate } from "react-router-dom";
import { editUser } from "../../services/userService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { Button, LabelWithValue } from "../../ui/index";
import { useDispatch, useSelector } from "react-redux";
import useMobile from "../../hooks/useOrientation";
import { ValidateEmail, ValidatePhone } from "../shared/regex";
import { useLocation } from "react-router-dom";
import { OverlayNotification } from "../OverlayNotification";
import { CenteredLoader } from "../shared/CenteredLoader";
import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SessionContext } from "../../helpers/session";
import secureLocalStorage from "react-secure-storage";
import { toggleOverlay } from "../../store/actions/notificationActions";
import { primary } from "../../ui/colors";

const disneyIcon = require("../../assets/disney.PNG");
const puntaCanaIcon = require("../../assets/puntacana.PNG");
const barilocheIcon = require("../../assets/bariloche.PNG");

library.add(faPlus);

const ProfileScreen = ({ route }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(SessionContext);
  const [passengers, setPassengers] = useState();
  const [isEditUserDataModalOpen, setIsEditUserDataModalOpen] = useState(false);
  const [editMessage, setEditMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const storePassengers = useSelector((state) => state.passengers);

  const navigate = useNavigate();
  const isMobile = useMobile();
  const location = useLocation();
  const dispatch = useDispatch();

  const isPasswordInsecure = location?.state?.isPasswordInsecure ?? null;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    passengersContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: 20,
      marginBottom: 20,
    },
    passengerContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      border: "1px solid black",
      borderRadius: 5,
      borderColor: primary,
      borderWidth: 2,
      gap: 10,
    },
    changePasswordButton: {
      width: 250,
      marginTop: 20,
      alignSelf: isMobile ? "center" : null,
    },
    title: {
      marginBottom: 10,
    },
  };

  useEffect(() => {
    if (isPasswordInsecure) {
      setIsModalOpen(true);
    }
    // dispatch(toggleOverlay({ show: true, text: "Proximo pago!" }));
  }, []);

  // useEffect(() => {
  //   setPassengers(user?.passengers);
  // }, []);
  useEffect(() => {
    setPassengers(storePassengers.passengers);
  }, [storePassengers]);

  if (!user) {
    return;
  }

  const handleAddNewPassenger = (id) => {
    navigate(`/registro`);
  };

  const handleEditUserData = (userData) => {
    if (userData.phone && !ValidatePhone(userData.phone)) {
      setEditMessage("Telefono invalido");
    } else if (userData.email && !ValidateEmail(userData.email)) {
      setEditMessage("Email invalido");
    } else if (userData.email || userData.phone) {
      const email = userData.email === user.email ? undefined : userData.email;
      const phone = userData.phone === user.phone ? undefined : userData.phone;
      if (!email && !phone) {
        setEditMessage("Debe editar el email o el telefono.");
      } else {
        setIsLoading(true);
        editUser(userData.id, email, phone)
          .then((res) => {
            secureLocalStorage.setItem("user", JSON.stringify(res));
            setEditMessage("Editado exitosamente");
          })
          .catch((e) => {
            setEditMessage(processErrorResponse(e)[1]);
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      setEditMessage("Debe editar el email o el telefono.");
    }
  };

  const handleChangeCloseEditPhone = () => {
    setEditMessage("");
    setIsEditUserDataModalOpen(false);
  };

  return (
    <>
      <OverlayNotification />
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <div style={styles.container}>
          <h1>Pasajeros</h1>
          <div style={styles.passengersContainer}>
            {passengers?.length === 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>No hay pasajeros creados.</span>
                <Button
                  style={styles.addTripButton}
                  onClick={() => handleAddNewPassenger()}
                >
                  <FontAwesomeIcon icon="plus" style={{ marginRight: 10 }} />
                  Agregar viaje
                </Button>
              </div>
            ) : (
              passengers?.map((p, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <PassengerItem styles={styles} p={p} index={index} />
                    {isMobile ? <Divider /> : null}
                  </div>
                );
              })
            )}
          </div>
          <h2 style={styles.title}>Datos de padre/madre/tutor</h2>
          <div>
            <LabelWithValue
              label={"Nombre"}
              value={user.name + " " + user.lastName}
            />
            <LabelWithValue label={"Email"} value={user.email} />
            <LabelWithValue label={"Telefono"} value={user.phone} />
          </div>
          <Button
            style={styles.changePasswordButton}
            variant="outlined"
            onClick={() => setIsModalOpen(true)}
          >
            Cambiar contraseña
          </Button>
        </div>
      )}
      {isModalOpen ? (
        <ChangePasswordModal
          onCloseModal={() => setIsModalOpen(false)}
          user={user}
        ></ChangePasswordModal>
      ) : null}
      {isEditUserDataModalOpen ? (
        <EditUserDataModal
          onCloseModal={() => handleChangeCloseEditPhone()}
          onEdituser={(data) => handleEditUserData(data)}
          user={user}
          editMessage={editMessage}
          loading={isLoading}
        />
      ) : null}
    </>
  );
};

export default ProfileScreen;

const PassengerItem = ({ p, styles, index }) => {
  const getIcon = () => {
    const tripName = p.programName?.toLowerCase() || ""; // Safely access programName or default to an empty string
    if (tripName.includes("disney")) {
      return disneyIcon;
    }
    if (tripName.includes("punta cana")) {
      return puntaCanaIcon;
    }
    if (tripName.includes("bariloche")) {
      return barilocheIcon;
    }
    return disneyIcon;
  };
  return (
    <div key={index} style={styles.passengerContainer}>
      <h3>
        {p.name} {p.lastName}
      </h3>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <img
            alt=""
            src={getIcon()}
            style={{ width: 60, height: "auto", paddingRight: 10 }}
          ></img>
        </div>
        <div>
          <LabelWithValue label={"Viaje:"} value={p.programName} />
          <LabelWithValue label={"Programa:"} value={p.tripName} />
          <LabelWithValue label={"Grupo:"} value={p.groupName} />
        </div>
      </div>
    </div>
  );
};
