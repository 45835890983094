function modularInverse(a, m) {
  let m0 = m,
    t,
    q;
  let x0 = 0,
    x1 = 1;

  if (m === 1) return 0;

  while (a > 1) {
    q = Math.floor(a / m);
    t = m;

    m = a % m;
    a = t;
    t = x0;

    x0 = x1 - q * x0;
    x1 = t;
  }

  if (x1 < 0) {
    x1 += m0;
  }

  return x1;
}

// Función de cifrado
function encrypt(id) {
  const prime = 10000019; // Módulo primo
  const multiplier = 37; // Multiplicador
  const increment = 123456789; // Desplazamiento

  // Cifrado simple usando multiplicación y un desplazamiento
  return (id * multiplier + increment) % prime; // Usa un primo como módulo
}

// Función de descifrado
// Función de descifrado
function decrypt(encryptedValue) {
  const prime = 10000019; // Módulo primo
  const multiplier = 37; // Multiplicador
  const increment = 123456789; // Desplazamiento

  // Deshacer el desplazamiento
  let tempValue = (encryptedValue - increment) % prime;
  if (tempValue < 0) {
    tempValue += prime; // Ajuste para asegurarse de que no sea negativo
  }

  // Encontrar el inverso multiplicativo del multiplicador
  const invMultiplier = modularInverse(multiplier, prime);

  // Deshacer la multiplicación
  return (tempValue * invMultiplier) % prime;
}

// Ejemplo de uso
export const encrypted = (number) => encrypt(number);

export const decrypted = (number) => decrypt(number);
