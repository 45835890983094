import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3>Lo sentimos, ocurrio un error. </h3>
      <Button onClick={() => navigate("/perfil")}>VOLVER</Button>
    </div>
  );
};

export default PageNotFound;
