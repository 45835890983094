import React, { useEffect, useState } from "react";
import { TextField, InputLabel } from "@mui/material";
import { NAME, EMAIL, PHONE_NUMBER } from "../../helpers/maxLenghts";
import FileUploader from "../shared/FileUploader";
import AddSignModal from "../payment/AddSingModal";
import { primary } from "../../ui/colors";
import SelectComponent from "../shared/SelectComponent";
import backgroundMobile from "../../assets/AlbumMobileSecond.jpg";
import backgroundDesktop from "../../assets/AlbumDesktopSecond.jpg";
import useMobileDetector from "../../hooks/useMobileDetector";
import { getHighSchools } from "../../services/passengerService";
import {
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "../../config";
import InputMask from "react-input-mask";
import { Button } from "../../ui";
import axios from "axios";
import { Data } from "@react-google-maps/api";
import { addContact } from "../../services/contacts";

const MARGIN_BOTTOM = 8;
const USD = "usd";

const image = {
  width: "100%",
  height: "auto",
};

const instagramLogo = require("../../assets/instagram.png");
const tikTokLogo = require("../../assets/tiktok.png");

const courses = [
  {
    id: 1,
    name: "Primero",
  },
  {
    id: 2,
    name: "Segundo",
  },
  {
    id: 3,
    name: "Tercero",
  },
  {
    id: 4,
    name: "Cuarto",
  },
  {
    id: 5,
    name: "Quinto",
  },
  {
    id: 6,
    name: "Sexto",
  },
];

const Battle = () => {
  const battle_image = require("../../assets/campeonato.PNG");

  const [highschools, setHighschools] = useState();
  const [data, setData] = useState({
    course: "Primero",
    courseId: 1,
  });
  const [sent, setSent] = useState();
  const [loading, setLoading] = useState();
  const [editable, setEditable] = useState(false);

  const isMobile = useMobileDetector();
  const styles = {
    cardsContainer: {
      display: "flex",
      flex: 1,
      height: 100,
      flexDirection: "row",
      gap: 20,
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      border: "1px",
      height: 200,
      border: "1px solid transparent", // Ensure border is always present
      borderRadius: 10,
      borderWidth: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
      justifyContent: "center",
    },
    dateInput: {
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: "1rem",
      paddingLeft: 14,
      paddingRight: 14,
      fontFamily: "Gilroy",
      marginBottom: 8,
      backgroundColor: "transparent",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.38)",
    },
    socialLogoStyles: {
      width: 40,
      height: "auto",
      cursor: "pointer",
    },
    sentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
    },
    socialContainer: {
      display: "flex",
      gap: 20,
      paddingTop: 20,
    },
  };

  useEffect(() => {
    getHighSchools().then((res) => {
      setHighschools(res);
    });
  }, []);

  const addUserData = async () => {
    try {
      // Accessing the collection and querying by documentId
      // const citiesRef = collection(database, "battles"); // Use collection() for accessing
      // const getByDocIdQuery = query(
      //   citiesRef,
      //   where("documentId", "==", data?.documentId)
      // ); // Create a query

      // // Execute the query
      // const querySnapshot = await getDocs(getByDocIdQuery);

      // let exists = false;
      // querySnapshot.forEach((doc) => {
      //   if (doc.data() && doc.data().documentId === data.documentId) {
      //     exists = true;
      //   }
      //   // You can work with the data here
      // });
      setLoading(true);
      const contactData = {
        eventName: "Formulario batallas",
        email: "",
        documentId: data.documentId,
        birthDate: data.userBirthDate,
        agesOfSiblings: null,
        phone: data.phone,
        highSchoolName: data.userHighschoolName,
      };
      try {
        const res = await addContact(contactData);
        console.log("Add contect response: ", res);
      } catch (e) {
        console.error("Failed adding contact data. ", e);
      }
      const docRef = await addDoc(collection(database, "battles"), data);
      setSent(true);
      setData();
      setLoading(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const onChangeDocument = (value) => {
    setData({
      ...data,
      ["documentId"]: value,
    });
    if (value.length === 8) {
      axios
        .get(`https://ci-uy.checkleaked.cc/${value}`)
        .then((res) => {
          const { nombre, apellidos, fechaNacimiento_raw } = res.data?.resp;
          setEditable(false);
          if (nombre && !fechaNacimiento_raw) {
            setEditable(true);
          }
          setData({
            ...data,
            ["documentId"]: value,
            ["userName"]: nombre,
            ["userLastName"]: apellidos,
            ["userBirthDate"]: fechaNacimiento_raw,
          });
        })
        .catch((e) => {
          console.error("CI no encontrada en registro", value);
        });
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingBottom: 20 }}
    >
      <img
        src={battle_image}
        style={{ width: 300, alignSelf: "center" }}
        alt=""
      />
      <div style={styles.formContainer}>
        {sent ? (
          <div style={styles.sentContainer}>
            <h2 style={{ alignSelf: "center", textAlign: "center" }}>
              Registro completado
            </h2>
            <h3 style={{ alignSelf: "center", textAlign: "center" }}>
              ¡Mucha suerte!
            </h3>
            <span style={{ textAlign: "center" }}>
              Seguinos en las redes sociales para seguir la competencia y no
              perderte de nada.
            </span>
            <div style={styles.socialContainer}>
              <img
                src={instagramLogo}
                alt=""
                style={styles.socialLogoStyles}
                onClick={() =>
                  window
                    .open("https://www.instagram.com/college.uy", "_blank")
                    .focus()
                }
              ></img>
              <img
                src={tikTokLogo}
                alt=""
                style={styles.socialLogoStyles}
                onClick={() =>
                  window
                    .open("https://www.tiktok.com/@college.uy", "_blank")
                    .focus()
                }
              ></img>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <h2 style={{ paddingBottom: 10 }}>Elegí tu equipo:</h2>
            <SelectComponent
              onChange={(val) =>
                setData({
                  ...data,
                  ["highschoolId"]: val.id,
                  ["highschoolName"]: val.name,
                  ["group"]: val.name,
                  ["userHighschoolId"]: val.id,
                })
              }
              label={"Elegir grupo al que te quieres unir"}
              val={data?.highschoolId}
              data={highschools}
            ></SelectComponent>
            {data?.highschoolId ? (
              <>
                <h2>Completá con tus datos:</h2>
                <TextField
                  style={{ marginBottom: MARGIN_BOTTOM }}
                  value={data?.documentId || ""}
                  onChange={(e) => onChangeDocument(e.target.value)}
                  placeholder="Cédula de identidad"
                  inputProps={{
                    maxLength: 8,
                  }}
                ></TextField>
                <TextField
                  style={{ marginBottom: MARGIN_BOTTOM }}
                  value={data?.userName || ""}
                  onChange={(e) =>
                    setData({ ...data, ["userName"]: e.target.value })
                  }
                  placeholder="Nombre"
                  inputProps={{
                    maxLength: NAME,
                    style: { textTransform: "capitalize" },
                  }}
                  disabled
                ></TextField>
                <TextField
                  style={{ marginBottom: MARGIN_BOTTOM }}
                  value={data?.userLastName || ""}
                  onChange={(e) =>
                    setData({ ...data, ["userLastName"]: e.target.value })
                  }
                  placeholder="Apellido"
                  inputProps={{
                    maxLength: NAME,
                    style: { textTransform: "capitalize" },
                  }}
                  disabled
                ></TextField>
                <InputMask
                  style={styles.dateInput}
                  mask="99/99/9999"
                  value={data?.userBirthDate || ""}
                  onChange={(val) =>
                    setData({ ...data, ["userBirthDate"]: val.target.value })
                  }
                  inputProps={{
                    maxLength: 9,
                  }}
                  placeholder="Fecha de nacimiento"
                  disabled={!editable}
                ></InputMask>
                <TextField
                  value={data?.phone || ""}
                  style={{ paddingBottom: 5 }}
                  onChange={(e) =>
                    setData({
                      ...data,
                      ["phone"]: e.target.value,
                    })
                  }
                  placeholder="Telefono"
                  inputProps={{ maxLength: PHONE_NUMBER }}
                ></TextField>
                <SelectComponent
                  onChange={(val) =>
                    setData({
                      ...data,
                      ["userHighschoolId"]: val.id,
                      ["userHighschoolName"]: val.name,
                    })
                  }
                  label={"Liceo al que vas?"}
                  val={
                    data?.userHighschoolId
                      ? data?.userHighschoolId
                      : data?.highschoolId
                  }
                  data={highschools}
                ></SelectComponent>
                <SelectComponent
                  onChange={(val) =>
                    setData({
                      ...data,
                      ["course"]: val.name,
                      ["courseId"]: val.id,
                    })
                  }
                  label={"Año que cursa?"}
                  data={courses}
                  val={data.courseId}
                ></SelectComponent>
                <Button
                  variant="contained"
                  onClick={addUserData}
                  isLoading={loading}
                  disabled={
                    loading ||
                    data.documentId?.length < 8 ||
                    !data.highschoolId ||
                    !data.userName ||
                    !data.userLastName ||
                    !data.userBirthDate ||
                    data.phone?.length !== 9 ||
                    !data.userHighschoolId
                  }
                >
                  Enviar
                </Button>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Battle;
