import { fetchGetApi } from "./fetch";

export const getTripById = async (id) => {
  const res = await fetchGetApi("/trips/" + id);
  return res;
};

export const getAllByProgramIdAndGroupName = async (programId, groupName) => {
  const res = await fetchGetApi(
    `/trips/public?programId=${programId}&groupName=${groupName}`
  );
  return res;
};
