import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { NAME, EMAIL, PHONE_NUMBER } from "../../../helpers/maxLenghts";
import AddSignModal from "../../payment/AddSingModal";

const MARGIN_BOTTOM = 8;
const USD = "usd";

const Responsible = ({ data, onChangeData }) => {
  const [paymentData, setPaymentData] = useState({
    ammount: 0,
    concept: "Seña",
    file: "",
    currency: USD,
  });
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 className="verticalPaddingText">
        Ingresar datos del madre/padre/tutor
      </h3>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.name}
        onChange={(e) => onChangeData({ ...data, ["name"]: e.target.value })}
        placeholder="Nombre"
        inputProps={{ maxLength: NAME, style: { textTransform: "capitalize" } }}
      ></TextField>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.lastName}
        onChange={(e) =>
          onChangeData({ ...data, ["lastName"]: e.target.value })
        }
        placeholder="Apellido"
        inputProps={{ maxLength: NAME, style: { textTransform: "capitalize" } }}
      ></TextField>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.email}
        onChange={(e) => onChangeData({ ...data, ["email"]: e.target.value })}
        placeholder="Email"
        inputProps={{ maxLength: EMAIL }}
      ></TextField>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.phone}
        onChange={(e) => onChangeData({ ...data, ["phone"]: e.target.value })}
        placeholder="Celular"
        inputProps={{ maxLength: PHONE_NUMBER }}
      ></TextField>
    </div>
  );
};

export default Responsible;
